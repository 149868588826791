<template>
    <div class="body-container">
        <BannerSection title="Prestamos con garantia de auto en Morelos" />
        <PerksComponent></PerksComponent>

        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <p class="mb-0">
                        ¿Necesitas dinero urgente y estás buscando <b>préstamos en Morelos</b> que te permitan solucionar tus problemas financieros rápidamente?
                    </p>
                    <p>
                        Estás en el lugar indicado. Aquí te explicaremos cómo funcionan estos préstamos, cuáles son las opciones disponibles en Morelos, y qué pasos seguir para conseguir el dinero que necesitas en el menor tiempo posible.
                    </p>
                    <span class="bold-text">
                        <br>
                        <h2 style="font-size: 20px;">¿Qué son los préstamos en Morelos?
                        </h2>
                    </span>

                    <p>
                        Los préstamos en Morelos son opciones de financiación disponibles para los habitantes de este estado que buscan una solución rápida para obtener dinero. Estas opciones suelen incluir préstamos personales y <router-link to="/prestamos-garantia-auto">préstamos con garantía de auto</router-link>, que permiten acceder a cantidades de dinero desde unos pocos cientos hasta miles de euros, dependiendo de la institución financiera y tus necesidades.
                    </p>
                    <p>
                        La ventaja principal de estos préstamos es que puedes conseguir el dinero en pocas horas tras completar una solicitud, muchas veces de forma completamente online.
                    </p>
                   
                    <br>
                    <span class="bold-text">
                        <br>
                        <h2 style="font-size: 20px;">¿Qué tipos de préstamos puedes encontrar en Morelos?
                        </h2>
                    </span>
                    <p>
                        En el estado de Morelos, hay diversas entidades que ofrecen soluciones de financiación a corto y medio plazo. Los tipos de préstamos más comunes incluyen:
                    </p>
                    <br>
                    <ul>
                        <li>
                            <p>
                                <b>Préstamos personales:</b> Son los más accesibles y no requieren que pongas un bien en garantía. Son ideales para cubrir gastos imprevistos, como reparaciones o viajes.
                            </p>
                        </li>
                        <li>
                            <p>
                                <b>Préstamos con garantía:</b> Si necesitas un importe mayor, puedes optar por un <b>préstamo con garantía de auto</b>, lo que te permitirá obtener mejores condiciones al utilizar tu coche como aval. Aunque esto implica mayores compromisos, es una opción flexible.
                            </p>
                        </li>
                        <li>
                            <p>
                                <b>Empeños en Morelos:</b> Los empeños te permiten obtener dinero rápido dejando un bien valioso (como joyas, aparatos electrónicos o vehículos) como garantía. Mientras devuelves el préstamo, el bien queda en depósito, pero si no puedes pagar, se vende para cubrir la deuda.
                            </p>
                        </li>
                    </ul>
                    <br>
                    <span class="bold-text">
                        <h2 style="font-size: 20px;">¿Cómo solicitar un préstamo en Morelos?</h2>
                    </span>
                    <p>
                        Pedir un <b>préstamo en Morelos</b> es muy sencillo, y el proceso suele ser rápido y sin complicaciones. Estos son los pasos básicos que debes seguir para solicitar uno:
                    </p>
                    <br>
                    <ol>
                        <li>
                            <p>
                                <b>Elige el tipo de préstamo:</b> Dependiendo de tu necesidad, selecciona el préstamo que mejor se ajuste a lo que buscas (personal, con garantía, empeño).
                            </p>
                        </li>

                        <li>
                            <p>
                                <b>Completa el formulario de solicitud:</b> La mayoría de las instituciones financieras en Morelos permiten hacer la solicitud online. Deberás proporcionar tus datos personales, así como detalles sobre el monto que necesitas.
                            </p>
                        </li>
                        <li>
                            <p>
                                <b>Revisión y aprobación:</b> Una vez completada la solicitud, la entidad financiera revisará tu información. Si cumples con los requisitos, recibirás una propuesta que incluye los términos del préstamo (intereses, plazos de pago, etc.).
                            </p>
                        </li>
                        <li>
                            <p>
                                <b>Recibe tu dinero:</b> Al aceptar la propuesta, el dinero será transferido a tu cuenta en cuestión de horas o días, dependiendo de la entidad.
                            </p>
                        </li>
                    </ol>
                    <br>
                    
                </div>
            </div>
        </div>


        <div style="background-color: rgba(255, 165, 0, 0.04); padding: 1.5rem 0rem; margin: 1.5rem 0rem;">
            <div class="ibancar-election">
                <p>¿Por qué elegir Ibancar?</p>
            </div>
            <BeneficiosIbancarCarousel />
        </div>

        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <br>
                    <span class="bold-text">
                        <h2 style="font-size: 20px;">¿Es mejor un préstamo o un empeño en Morelos?</h2>
                    </span>
                    <p>
                        Elegir entre un <b>préstamo en Morelos</b> o un empeño en Morelos depende de tu situación y del valor de tus bienes. Ambos tienen sus ventajas:
                    </p>
                    <br>
                    <ul>
                        <li>
                            <p>
                                <b>Préstamos personales o con garantía:</b> Son ideales si no quieres perder el uso de tus bienes mientras devuelves el préstamo. Un <b>préstamo con garantía de auto</b> te permite obtener una suma mayor sin perder el uso de tu coche.
                            </p>
                        </li>

                        <li>
                            <p>
                                <b>Empeños en Morelos:</b> Si necesitas dinero de manera inmediata y no te importa dejar un bien como garantía, los empeños pueden ser una opción conveniente. Sin embargo, es importante tener en cuenta que, si no pagas a tiempo, puedes perder el bien empeñado.
                            </p>
                        </li>
                    </ul>
                    <br>
                    <span class="bold-text">
                        <h2 style="font-size: 20px;">Ventajas de los préstamos en Morelos</h2>
                    </span>
                    <p>
                        Optar por un <b>préstamo en Morelos</b> tiene muchas ventajas, entre ellas:
                    </p>
                    <br>
                    <ul>
                        <li>
                            <p>
                                <b>Acceso rápido a dinero:</b> Puedes obtener el dinero en menos de 24 horas en algunos casos, lo que lo convierte en una solución perfecta para emergencias.
                            </p>
                        </li>
                        <li>
                            <p>
                                <b>Proceso sencillo:</b> Las entidades suelen pedir pocos documentos y los procesos de solicitud son rápidos y fáciles de completar, especialmente si se hacen online.
                            </p>
                        </li>
                        <li>
                            <p>
                                <b>Diversidad de opciones:</b> Dependiendo de tus necesidades, puedes elegir entre diferentes tipos de préstamos con condiciones que se ajusten a ti.
                            </p>
                        </li>
                    </ul>

                    <br>
                    <span class="bold-text">
                        <h2 style="font-size: 20px;">¿Qué requisitos necesito para solicitar un préstamo en Morelos?</h2>
                    </span>
                    <p>
                        Los requisitos varían según la entidad, pero generalmente te pedirán identificación oficial, comprobante de ingresos y en algunos casos, documentación de un bien si es un <b>préstamo con garantía de auto</b>.
                    </p>

                    <br>
                    <span class="bold-text">
                        <h2 style="font-size: 20px;">¿Cuánto tarda en aprobarse un préstamo en Morelos?</h2>
                    </span>
                    <p>
                        El tiempo de aprobación puede variar, pero muchas entidades aprueban el préstamo y transfieren el dinero en menos de 24 horas si cumples con los requisitos.
                    </p>
                    <br>
                    <span class="bold-text">
                        <h2 style="font-size: 20px;">¿Qué pasa si no puedo pagar mi préstamo o empeño en Morelos?</h2>
                    </span>
                    <p>
                        En el caso de un préstamo, deberás comunicarte con la entidad para buscar una solución. Si es un empeño y no pagas en el plazo acordado, el bien empeñado será vendido para cubrir la deuda.
                    </p>
                    <p>
                        Solicitar <b>préstamos en Morelos</b> es una opción rápida y segura para cubrir cualquier necesidad financiera. Ya sea que elijas un préstamo personal o un empeño, siempre es importante comparar las opciones y entender bien las condiciones antes de tomar una decisión.
                    </p>
                    <br>
                </div>
            </div>
        </div>
     
        <SolicitaloBannerS2 />
    </div>
</template>

<script>
import { useMeta } from 'vue-meta';
import BannerSection from '@/components/Landings/BannerSection.vue';
import BeneficiosIbancarCarousel from '@/components/PrestamoGarantiaAuto/BeneficiosIbancarCarousel.vue';
import SolicitaloBannerS2 from '@/components/Landings/SolicitaloBannerS2.vue';
import PerksComponent from '../components/PerksComponent.vue';


export default {
    name: 'PrestamosDeDineroRapido',
    components: {
        BannerSection,
        BeneficiosIbancarCarousel,
        SolicitaloBannerS2,
        PerksComponent
    },
    setup() {
        useMeta(
            {
                title: 'Prestamos con garantia de auto en Morelos',
                meta: [
                    {
                        name: 'robots',
                        content: 'index'
                    },
                    {
                        name: 'description',
                        content: 'Resuelve tus emergencias con préstamos en Morelos. Obtén dinero rápido, trámites sencillos y opciones flexibles como préstamos con garantía de auto. Solicita en línea y recibe tu dinero en menos de 24 horas. ¡Fácil, seguro y confiable!'
                    },
                    {
                        name: 'keywords',
                        content: 'prestamos en Morelos - empeños morelos'
                    }
                ],
                link: [
                    // { href: 'https://ibancar.com/prestamos-con-aval-de-coche-sin-cambiar-de-titular', rel: 'alternate', hreflang: 'es-es' },
                    { href: 'https://ibancar.mx/prestamos-garantia-auto-morelos', rel: 'alternate', hreflang: 'es-MX' }
                ]
            }
        )
    },

    data() {
        return {
            ventajas: [
                {
                    image: 'image12.svg',
                    title: 'Ejecutivo personalizado',
                },
                {
                    image: 'solicitud-en-linea.svg',
                    title: 'Proceso simple sin salir de casa',
                },

                {
                    image: 'prestamos-48-hrs.svg',
                    title: 'Hasta $120,000 en 24H',
                },
                {
                    image: 'proceso-simple.svg',
                    title: 'Aceptamos personas en Buró.',
                }

            ]
        }
    }
}
</script>

<style scoped lang="scss">
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';


.image-container {
    text-align: center;
    margin: 3rem 0rem;

    img {
        max-height: 400px;
    }
}

.body-container {
    margin-top: 4.5rem;
    min-height: calc(100vh - 115px);
}

@include media-breakpoint-up(md) {
    .body-container {
        margin-top: 8.5rem;
    }
}

.ibancar-election {
    margin: 1rem 2rem 0rem 2rem;

    p {
        color: #004680;
        text-align: center;
        font-family: 'Montserrat-Bold';
        font-size: 1.3rem;
        font-style: normal;
        line-height: normal;
        margin: 1.5rem 0rem;
    }
}

.text-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    text-align: justify;
}

.regular-text {
    font-family: "Montserrat";
    font-size: 20px;
    line-height: 30px;
}

.title {
    font-size: 25px;
    line-height: 30px;
}

.bold-text {
    font-family: "Montserrat-Bold";
}

.text-orange {
    color: $orange;
}

.text-blue {
    color: $blue;
}

.text-black {
    color: $black;
}

.text-underline {
    text-decoration: underline;
}


.btn-solicitar {
    a {
        &:hover {
            text-decoration: none;
        }
    }
}

// Image
.image {
    bottom: 0;
    right: 0;
    width: 30%;
    z-index: -1;
}

@include media-breakpoint-down(md) {
    .text-container {
        width: 90%;
    }

    .regular-text {
        line-height: 1.5;
    }

    ul {
        padding-inline-start: 20px;
    }

    ol {
        padding-inline-start: 20px;
    }
}

@include media-breakpoint-between(md, lg) {
    .regular-text {
        font-size: 16px;
    }

    .title {
        font-size: 28px;
        line-height: 35px;
    }

    .subtitle {
        font-size: 16px;
    }
}

@include media-breakpoint-down(sm) {
    .title {
        font-size: 20px;
        line-height: 25px;
    }

    .subtitle {
        font-size: 16px;
        line-height: 20px;
    }

    .regular-text {
        font-size: 16px !important;
    }

    .section-container {
        margin-top: 1.5rem;
    }
}
</style>